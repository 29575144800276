.Container {
    display: grid;
    margin: 1rem 2rem;
    gap: 1.3rem;
}
.Container header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    overflow: auto;
}
.Container > h3 {
    font-size: var(--headerSize);
    color: var(--HeadingColor);
    font-weight: 600;
}

.Master_data_filter {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 1rem;
    flex-wrap: wrap-reverse;
}
.filter_right {
    display: grid;
    width: 100%;
    max-width: 500px;
}
.form_control {
    position: relative;
    display: grid;
}
.form_control_error {
    border: 1px solid red !important;
}
.form_control input {
    width: 100%;
    border: 1px solid #e0e2e7;
    height: 2.4rem;
    border-radius: 10px;
    text-indent: 2rem;
    outline: var(--PrimaryColor) !important;
}
.search {
    position: absolute;
    top: 11px;
    left: 10px;
    font-size: 1.2rem;
    color: lightgray;
}
.filter_btns {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}
.filter_btns button:first-child,
.download {
    background-color: #cdfcf2;
    color: var(--PrimaryColor);
    display: flex;
    border: none;
    align-items: center;
    border-radius: 10px;
    gap: 0.5rem;
    padding: 0.5rem;
    /* font-weight: bold; */
    cursor: pointer;
    font-size: 0.9rem;
    text-decoration: none;
}
.filter_btns button:nth-child(2) {
    color: var(--PrimaryColor);
    border: 1px solid var(--PrimaryColor);
    display: flex;
    /* border: none; */
    align-items: center;
    border-radius: 10px;
    gap: 0.5rem;
    padding: 0.5rem;
    font-weight: 550;

    cursor: pointer;
}
.filter_btns button:nth-child(2):hover {
    background-color: var(--PrimaryColor);
    border: 1px solid var(--PrimaryColor);
    color: white;
}
.Modal_Container {
    display: grid;
    gap: 0.5rem;
}
.Modal_Container header {
    padding: 1.5rem 1rem;
    border-bottom: 1px solid #ccc;
}
.Modal_Container header h3 {
    font-size: var(--headerSize);
    color: var(--HeadingColor);
    font-weight: 600;
    font-size: 1.2rem;
}
.Action_btns {
    position: absolute;
    bottom: 10px;
    background-color: white;
    right: 10px;
    left: 10px;
    display: flex;
    padding-top: 1rem;
    gap: 1rem;
    border-top: 1px solid #ccc;
    justify-content: flex-end;
}
.Action_btns button {
    padding: 0.3rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    color: white;
}
.Action_btns button:nth-child(1) {
    border: 1px solid var(--PrimaryColor);
    background-color: var(--PrimaryColor);
}
.Action_btns button:nth-child(2) {
    border: 1px solid red;
    background-color: red;
}
.Modal_Container form {
    padding-top: 1rem;
    display: grid;
    justify-self: center;
    gap: 1rem;
    width: 90%;
}
.Modal_Container .form_control {
    display: grid;
    padding: 0 1rem;
    gap: 1rem;
}
.Modal_Container .form_control input,
.Modal_Container .form_control textarea {
    text-indent: 0.7rem;
    border-color: black;
    border-radius: 5px;
    width: 100%;
    outline: none;
    font-family: inherit;
}
.Modal_Container .form_control textarea {
    resize: none;
    padding: 0.2rem;
    width: calc(100% - 0.2rem);
}
.form_add_devalaya {
    grid-template-columns: 1fr;
}
.form_control_upload {
    grid-column: 1/-1;
}
.form_add_devalaya .fieldset {
    grid-template-columns: 1fr 1fr;
    padding: 1rem;
    display: grid;
    gap: 1rem;
    border: 1px solid #ccc;
}
.AbhirunchiWrapper {
    display: grid;
    gap: 1rem;
    margin-top: 1rem;
}
.Radio-btn {
    display: flex;
    gap: 2.5rem;
    align-items: center;
}
.Radio-btn label {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;
}
@media screen and (max-width: 767px) {
    .Container {
        margin: 1rem;
    }
}

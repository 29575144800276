.Container {
    display: grid;
    margin: 1rem 2rem;
    gap: 1.3rem;
}
.Container > header,
.Add_header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
}
.Add_header {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
}
.Container > header h3,
.Add_form_wrapper > h3,
.Add_header > h3 {
    font-size: var(--headerSize);
    color: var(--HeadingColor);
    font-weight: 600;
}
.Add_cat_btn {
    border: 1px solid var(--BorderColor);
    padding: 0.6rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: white;
    color: var(--PrimaryColor);
    border-radius: 5px;
    font-weight: 500;
    font-size: 15px;
}
.Add_cat_btn:hover {
    background-color: var(--PrimaryColor);
    color: white;
    cursor: pointer;
}
.tableWrapper {
    width: 100%;
    overflow: auto;
}

.tableWrapper table {
    border-collapse: collapse;
    width: 100%;
    min-width: 700px;
    text-align: left;
    overflow: auto;
}
.tableWrapper table th {
    font-size: 14px;
    color: var(--tableHeaderColor);
    background-color: var(--tableheaderBg);
    padding: 1rem;
    font-weight: 400;
    text-align: center;
}
.tableWrapper table th:first-child {
    border-top-left-radius: 10px;
}
.tableWrapper table th:last-child {
    border-top-right-radius: 10px;
}
.tableWrapper tbody tr:not(:last-child) {
    border-bottom: 1px solid #e3dada;
}

.tableWrapper table tbody tr:last-child td:last-child,
.tableWrapper table tbody tr:last-child td:first-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.tableWrapper table tbody tr {
    background-color: var(--tableHeaderColor);
}
.tableWrapper table tbody tr td {
    font-family: "Public Sans", sans-serif;
    color: #667085;
    padding: 1rem;
    font-size: 14px;
    vertical-align: center;
    text-align: center;
}
.banner {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    justify-content: center;
}
.banner img {
    width: 3rem;
    height: 3rem;
    border-radius: 10px;
}
.banner div p {
    font-size: 14px;
    font-weight: 500;
    color: #1d1f2c;
}
.Active {
    background-color: #def7ec;
    color: #008249;
    padding: 0.4rem 0.7rem;
    border-radius: 10px;
    font-size: 14px;
}
.Action_Tabs {
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
}
.Action_Tabs button {
    border: none;
    background-color: inherit;
    font-size: 1.1rem;
    color: gray;
    cursor: pointer;
}
.Action_Tabs button img {
    width: 0.9rem;
}
.Add_form_wrapper {
    margin: 1rem 3rem 1rem 2rem;
    display: grid;
    gap: 1rem;
}
.Add_form_wrapper form {
    display: grid;
    gap: 2rem;
}
.Add_form_wrapper .form {
    box-shadow: 0px 4px 30px 0px #2e2d740d;
    padding: 1rem;
    background-color: white;
    border-radius: 10px;
    display: grid;
    align-content: baseline;
    gap: 1rem;
}
.form_control {
    display: grid;
    align-content: baseline;
    gap: 0.5rem;
}
.form_control label,
.form_control_quill label {
    font-size: 1rem;
    color: #777980;
    font-size: 550;
}
.form_control label span {
    color: red;
}

.form_control_quill {
    height: 100%;
}

.Add_form_wrapper form h4 {
    color: #1d1f2c;
    font-size: 18px;
    font-weight: 600;
}
.form_control input,
.form_control select {
    border: 1px solid #e0e2e7;
    height: 2.3rem;
    border-radius: 5px;
    outline: none;
    text-indent: 10px;
    width: 100%;
}
.form_control > p,
.ErrorMsg,
.Gen-erro_msg {
    color: white;
    background-color: rgb(207, 6, 6);
    padding: 0.2rem 0.5rem 0.2rem 0.2rem;
    font-size: 0.9rem;
    border-radius: 3px;
    width: fit-content;
    display: flex;
    gap: 0.5rem;
    align-items: center;
}
.Add_form_wrapper form > button {
    justify-self: center;
    background-color: var(--PrimaryColor);
    color: white;
    font-weight: 500;
    border: none;
    font-size: 1rem;
    padding: 0.5rem 3rem;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid var(--PrimaryColor);
}
.Add_form_wrapper form > button:hover {
    background-color: white;
    color: #008249;
}
.form_control_error {
    border: 1px solid red !important;
}
.category_head {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    user-select: none;
}
.category_content {
    gap: 1rem;
    display: inline-flex;
    cursor: pointer;
    user-select: none;
}

.category_content h3 {
    margin-bottom: 0.3rem;
    font-size: 15px;
    color: black;
}
.category_content p {
    font-size: 13px;
}
.Add_form_wrapper_Category form {
    display: grid;
    grid-template-columns: 300px 1fr;
}
.Add_form_wrapper form .form_control textarea,
.form_control textarea {
    resize: none;
    border: 1px solid #e0e2e7;
    font-family: inherit;
    border-radius: 5px;
    outline: none;
    text-indent: 10px;
}
.add_btns_submit {
    margin: 1rem 0;
    justify-self: center;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}
.add_btns_submit button {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.4rem;
}
.add_btns_submit button:first-child {
    border: 1px solid #ccc;
    color: #858d9d;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}
.add_btns_submit button:nth-child(2),
.add_btns_submit button:first-child:hover {
    color: var(--PrimaryColor);
    border-radius: 5px;
    border: 1px solid var(--PrimaryColor);
    font-size: 15px;
    cursor: pointer;
}
.add_btns_submit button:nth-child(2):hover {
    background-color: var(--PrimaryColor);
    color: white;
}
.form_secondary {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}
.form_trio {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
}
.form .fieldset {
    padding: 1rem;
    display: grid;
    gap: 1rem;
    border: 1px solid #ccc;
}
.remove_upload {
    /* background-color: lightgreen; */
    border: 1px dashed #ccc;
    padding: 0.5rem;
    margin: 0.5rem;
    max-width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.remove_upload div {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
}
.remove_upload div p {
    text-decoration: underline;
    color: rgb(137, 130, 238);
}
.remove_upload button {
    color: red;
    border: none;
    font-size: 1rem;
    background-color: inherit;
    cursor: pointer;
}
.remove_upload img {
    width: 3rem;
}

/* ----Tabs--------------- */

.Tabs {
    display: flex;
    background-color: white; /* Soft gray background */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    overflow: auto;
    /* min-width: 1000px; */
}
.Tabs button {
    padding: 12px 20px;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    font-family: inherit;
    color: #666; /* Lighter gray text */
    background-color: white; /* Same as the container background */
    border: none;
    transition: color 0.3s, background-color 0.3s;
}
.active_tab {
    color: #fff !important; /* White text for active tab */
    background-color: var(
        --PrimaryColor
    ) !important; /* Dark background for active tab */
    border-radius: 8px 8px 0 0;
}
.Tabs button:hover:not(.active_tab) {
    background-color: #e0e0e0; /* Light gray hover effect */
}

.Action_approve_reject {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}
.Action_approve_reject button:nth-child(1) {
    background-color: inherit;
    border: none;
    color: black;
}
.Action_approve_reject button:nth-child(2) {
    background-color: green;
    border: 1px solid green;
    padding: 0.4rem;
    cursor: pointer;
    border-radius: 10px;
    color: white;
}
.Action_approve_reject button:nth-child(2):hover {
    background-color: white;
    border: 1px solid green;

    color: green;
}
.Action_approve_reject button:nth-child(3) {
    background-color: red;
    border: 1px solid red;
    padding: 0.4rem;
    cursor: pointer;
    border-radius: 10px;
    color: white;
}
.Action_approve_reject button:nth-child(3):hover {
    background-color: white;
    border: 1px solid red;

    color: red;
}
.Pooja_items {
    display: grid;
    gap: 1rem;
    grid-column: 1/-1;
}
.Pooja_items h3 {
    font-size: 1.1rem;
    color: var(--HeadingColor);
    font-weight: 600;
    grid-column: 1/-1;
}
.Pooja_items > button {
    width: fit-content;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    background-color: inherit;
    font-size: 0.9rem;
    color: white;
    background-color: green;
    padding: 0.3rem;
    border-radius: 10px;
    justify-self: right;
    cursor: pointer;
}
.danger {
    background-color: red;
    color: white;
    /* padding: 0.5rem; */
    padding: 0.3rem;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}
.danger:disabled {
    background-color: #ccc;
    color: white;
    cursor: not-allowed;
}
.pooja_Item {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    align-items: center;
    gap: 1rem;
}
@media screen and (max-width: 767px) {
    .Container,
    .Add_form_wrapper {
        margin: 1rem;
    }
    .Container > header h3,
    .Add_form_wrapper > h3,
    .Add_header h3 {
        font-size: clamp(1.3rem, 1.8rem, 4vw);
    }
    .tableWrapper table th {
        font-size: clamp(0.9rem, 1.2rem, 2vw);
    }
    .tableWrapper table td {
        font-size: 0.9rem;
    }
    .form_secondary {
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 1000px) {
    .Add_form_wrapper_Category form {
        grid-template-columns: 1fr;
    }
}

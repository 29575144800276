.Container {
    display: grid;
    grid-template-columns: 1fr 42%;
    height: 100vh;
}
.Container_left {
    display: grid;
    align-items: center;
    justify-items: center;

    align-content: center;
}
.Container form .Logo_wrap > img {
    width: 7rem;
    border-radius: 10px;
}
.Logo_wrap {
    justify-self: center;
    align-self: center;
    text-align: center;
    display: grid;
    gap: 1rem;
    align-items: center;
    justify-items: center;
}
.Container form {
    display: grid;
    align-items: center;
    align-content: center;
    width: 100%;
    border-radius: 10px;
    max-width: 400px;
    padding: 2rem;
    gap: 1.2rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.Container_left form h3 {
    font-size: 20px;
    color: #333335;
    margin-bottom: 0.5rem;
}
.Container_left form p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.01rem;
    color: #8c9097;
    opacity: 0.7;
    margin-bottom: 1rem;
}
.form_control {
    width: 100%;
    display: grid;
    gap: 0.6rem;
}
.form_control label {
    font-size: 0.9rem;
    color: #333335;
}
.input_wrapper {
    width: 100%;
    display: flex;
}

.form_control input {
    width: 100%;
    border: 1px solid #e9edf6;
    height: 2.7rem;
    border-radius: 0.35rem;
    text-indent: 0.5rem;
    outline: none;
    font-size: 1rem;
}
.form_control span {
    color: red;
    font-size: 0.8rem;
}
.Container_left form > button {
    background-color: #ff7a0d;
    height: 3rem;
    border: none;
    font-weight: 500;
    color: white;
    cursor: pointer;
    border-radius: 0.35rem;
    font-size: 1rem;
}
.Container_left form > button:hover {
    opacity: 0.7;
}
.input_wrapper button {
    height: 100%;
    width: 3rem;
    border: none;
    font-size: 1.1rem;
    border-bottom-right-radius: 0.35rem;
    border-top-right-radius: 0.35rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.input_wrapper input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.Container_right {
    background-image: url("../../../Assets/login_bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.Container_right::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    inset-block-end: 0;
    background-color: rgba(132, 90, 223, 0.2);
}
.rightbox {
    width: 70%;
    height: 60%;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(1.875rem);
    backdrop-filter: blur(1.5rem);
    padding: 2rem;
    position: relative;
    display: grid;
    align-content: center;
    align-items: center;
    justify-items: center;
    gap: 0.4rem;
}
.image_wrapper {
    margin-bottom: 0.5rem;
}

.rightbox p {
    margin-bottom: 0.2rem;
    font-size: 13px;
    color: #ccc;
}
.rightbox h3 {
    font-size: 1.2rem;
    color: #ffffff;
}

@media screen and (max-width: 1000px) {
    .Container {
        grid-template-columns: 1fr;
        justify-items: center;
    }
    .Container_right {
        display: none;
    }
    .Container_left {
        justify-items: center;
    }
    .Container_left,
    .Container form {
        width: 90%;
    }
}
